import React from 'react'
import { post } from 'axios';

import styles from './rsvp-page.module.scss';

const input = ({ name, update, ...props }) => (
  <input
    onChange={({target}) => {
      const value = target.type === 'checkbox' ? target.checked : target.value;
      update({ name, value })
    }}
    {...props}
    value={props.value || props.checked}
    required
  />
);

export default class RSVPForm extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    attending: '',
    hasGuest: '',
    guestFirstName: '',
    guestLastName: '',
    dietaryRestrictions: '',
    sunday: '',
    email: '',
    note: '',
    noteCharacters: 0,
    showError: false
  };

  FILTER_KEYS = ['noteCharacters', 'loading', 'showError'];

  get cleanData() {
    const data = {};
    
    Object.keys(this.state)
      .filter((key) => !this.FILTER_KEYS.includes(key))
      .forEach((key) => data[key] = this.state[key]);

    return data;
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ showError: false });
    this.props.toggleLoader();
    post('https://wedding-234021.appspot.com/rsvp', this.cleanData)
      .then((res, err) => {
        this.props.toggleLoader();
        if (err) {
          this.setState({ showError: true });
          console.error("ERROR:", err);
        }
        return this.props.handleSubmit(this.state.attending === 'attending');
      })
      .catch((err) => {
          this.setState({ showError: true });
          this.props.toggleLoader();
      });

  }

  updateForm = ({ target: {name, value }}) => this.setState({ [name]: value });

  attendingFields = () => (
    <React.Fragment>
      <fieldset className={styles.inputGroup}>
        <label htmlFor="hasGuest">Do you have a guest?</label>
        <p className={styles.hint}>If you are unsure whether or not you were invited with a guest, please get in touch with Dan or Sonya.</p>
        <div className={styles.radioGroup}>
          <div className={styles.option}>
            <input
              value={true}
              onChange={this.updateForm}
              name="hasGuest"
              type="radio"
              checked={this.state.hasGuest === "true"}
              required={this.state.attending === "attending"}
            />
            <label htmlFor="YES">Yep!</label>
          </div>
          <div className={styles.option}>
            <input
              value={false}
              onChange={this.updateForm}
              name="hasGuest"
              type="radio"
              checked={this.state.hasGuest === 'false'}                
              required={this.state.attending === "attending"}
            />
            <label htmlFor="NO">Nope</label>
          </div>
        </div>
      </fieldset>
      { this.state.hasGuest === 'true' &&
        (
          <React.Fragment>
            <fieldset className={styles.inputGroup}>
              <label htmlFor="guestFirstName">Guest's First name</label>
              <input
                className={styles.textField}
                value={this.state.guestFirstName}
                onChange={this.updateForm}
                name="guestFirstName"
                required={this.state.hasGuest === 'true'}
                min={3}
              />
            </fieldset>
            <fieldset className={styles.inputGroup}>
              <label htmlFor="guestLastName">Guest's Last name</label>
              <input
                className={styles.textField}
                value={this.state.guestLastName}
                onChange={this.updateForm}
                name="guestLastName"
                required={this.state.hasGuest === 'true'}
                min={3}
              />
            </fieldset>
          </React.Fragment>      
        )
      }
      <fieldset className={styles.inputGroup}>
        <label htmlFor="dietaryRestrictions">Any dietary restrictions?</label>
        <input
          className={styles.textField}
          placeholder="e.g. nuts, gluten, hollandaise"
          value={this.state.dietaryRestrictions}
          onChange={this.updateForm}
          name="dietaryRestrictions"
        />
      </fieldset>
      <fieldset className={styles.inputGroup}>
        <label htmlFor="sunday">Will you be joining us for breakfast Sunday?</label>
        <div className={styles.radioGroup}>
          <div className={styles.option}>
            <input
              value={true}
              onChange={this.updateForm}
              checked={this.state.sunday === "true"}
              name="sunday"
              type="radio"
              required={this.state.attending === "attending"}
            />
            <label htmlFor="true">Yep, I love breakfast!</label>
          </div>
          <div className={styles.option}>
            <input
              value={false}
              onChange={this.updateForm}
              name="sunday"
              type="radio"
              checked={this.state.sunday === 'false'}                
              required={this.state.attending === "attending"}
            />
            <label htmlFor="NO">Nope, mornings are difficult</label>
          </div>
        </div>
      </fieldset>
      <fieldset className={styles.inputGroup}>
        <label htmlFor="email">Give us an email so we can get in touch!</label>
        <input
          className={styles.textField}
          value={this.state.email}
          onChange={this.updateForm}
          name="email"
          autoComplete="email"
          type="email"
          required={this.state.attending === "attending"}
        />
      </fieldset>
    </React.Fragment>
  )

  render() {
    return (
      <form onSubmit={this.submitForm}>
        <h1 className={styles.header}>RSVP</h1>
        <fieldset className={styles.inputGroup}>
          <label htmlFor="firstName">First name</label>
          <input
            className={styles.textField}
            value={this.state.firstName}
            onChange={this.updateForm}
            name="firstName"
            autoComplete="given-name"
            required
            autoFocus
            min={3}
          />
        </fieldset>
        <fieldset className={styles.inputGroup}>
          <label htmlFor="lastName">Last name</label>
          <input
            className={styles.textField}
            value={this.state.lastName}
            onChange={this.updateForm}
            name="lastName"
            required
            autoComplete="family-name"
            min={3}
          />
        </fieldset>
        <fieldset className={styles.inputGroup}>
          <label htmlFor="attending">Will you be attending?</label>
          <div className={styles.radioGroup}>
            <div className={styles.option}>
              <input
                checked={this.state.attending === 'attending'}
                onChange={this.updateForm}
                name="attending"
                type="radio"
                value="attending"
                required
              />
              <label htmlFor="YES">Sure will!</label>
            </div>
            <div className={styles.option}>
              <input
                checked={this.state.attending === 'not attending'}
                onChange={this.updateForm}
                name="attending"
                type="radio"
                value="not attending"
              />
              <label htmlFor="NO">Can't :'(</label>
            </div>
          </div>
        </fieldset>
        { this.state.attending === 'attending' && this.attendingFields() }
        <fieldset className={styles.inputGroup}>
          <label htmlFor="note">Note</label>
          <textarea
            name="note"
            value={this.state.note}
            onChange={(e) => {
              this.updateForm(e);
              this.setState({ noteCharacters: e.target.value.length })
            }}
            rows="6"
            cols="40"
          />
          <p className={styles.aside}>{this.state.noteCharacters || 0} / unlimited because we love you</p>
        </fieldset>        
        <button className={styles.cta} type="submit">Submit RSVP</button>
        { this.state.showError &&
          <React.Fragment>
            <p style={{color: "red"}}>Oh no, something went wrong!</p>
            <p style={{color: "red"}}>Please try again or get in touch with Dan and Sonya.</p>
          </React.Fragment>
        }
      </form>
    )
  }
}
